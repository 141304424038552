@font-face {
  font-family: 'tickingTimeBomb';
  font-style: normal;
  font-weight: normal;
  src: url('/static/assets/fonts/ticking-timebomb-bb.regular.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --backgroundColor: #8f00fd;
}

html,
body {
  position: relative;
  overflow: hidden;
  font-family: 'tickingTimeBomb', sans-serif;
  background-color: #000;
  margin: 0px;
  height: 100%;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.game-things {
  visibility: hidden;
  &.show {
    visibility: visible;
  }
}

.info-panel {
  display: flex;
  align-items: start;
  flex-direction: column;
  position: fixed;
  bottom: 3%;
  left: 3%;
  z-index: 100;
  .speedometer {
    margin-top: 0.2em;
    color: aliceblue;
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: white;
    text-shadow: 0px 0px 5px white;
    paint-order: stroke fill;
    font-size: 8em;
    line-height: 0.8em;
  }
}

.music-player {
  background-color: rgba(0, 0, 0, 0.85);
  border: 1px solid var(--backgroundColor);
  box-shadow: 0px 0px 1px var(--backgroundColor);
  transition: box-shadow 0.1s ease-in, filter 0.2s ease-in;
  &:hover {
    box-shadow: 0px 0px 5px var(--backgroundColor);
    filter: brightness(1.2);
    .title {
      filter: brightness(1.75);
      transition: filter 0.2s ease-in;
    }
  }
  audio {
    display: none;
  }
  .icon img {
    width: 5.6em;
    vertical-align: middle;
  }
  .control-wrapper {
    display: inline-grid;
    padding: 0 0.8em 0 0.5em;
    vertical-align: middle;
    filter: brightness(1);

    .title {
      text-decoration: none;
      font-size: 1.5em;
      display: block;
      text-shadow: 0px 0px 2px var(--backgroundColor);
      color: var(--backgroundColor);
      line-height: 0.9em;
      grid-column: span 2;
      grid-row: 1;
    }
  }
  .btn {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    border: none;
    border-radius: 7px;
    background: none;
    padding: 0;
    cursor: pointer;
    overflow: hidden;
    &:hover,
    &:focus,
    &:active {
      border-color: var(--backgroundColor);
      filter: brightness(1.75);
      .bar {
        background-color: var(--backgroundColor);
      }
    }
    .bar {
      display: inline-block;
      position: absolute;
      background-color: var(--backgroundColor);
      top: 5px;
      left: 0;
      width: 3px;
      height: 14px;
      border-radius: 10px;
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      transform-origin: center;
      box-shadow: 0px 0px 2px var(--backgroundColor);
    }
    &.play {
      .bar-1 {
        transform: translateX(10px) translateY(0px) rotate(-55deg);
      }
      .bar-2 {
        transform: translateX(10px) translateY(7px) rotate(-125deg);
      }
    }
    &.pause {
      .bar-1 {
        transform: translateX(6px) translateY(2px) rotate(0deg);
      }
      .bar-2 {
        transform: translateX(14px) translateY(2px) rotate(0deg);
      }
    }
  }
  .speaker {
    margin-left: 1em;
    .speaker-span {
      display: block;
      width: 8px;
      height: 8px;
      background: var(--backgroundColor);
      margin: 2px 0 0 2px;
      box-shadow: 0px 0px 2px var(--backgroundColor);

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent var(--backgroundColor) transparent transparent;
        border-width: 10px 14px 10px 15px;
        left: -13px;
        top: 5px;
      }
      &:before {
        transform: rotate(45deg);
        border-radius: 0 50px 0 0;
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-style: double;
        border-color: var(--backgroundColor);
        border-width: 7px 7px 0 0;
        left: 18px;
        top: 9px;
        transition: transform 0.2s ease-out;
        box-shadow: 0px 0px 2px var(--backgroundColor);
      }
    }
    &:hover {
      .speaker-span:before {
        transform: scale(0.8) translate(-3px, 0) rotate(42deg);
      }
    }
    &.muted {
      .speaker-span:before {
        transform: scale(0.5) translate(-15px, 0) rotate(36deg);
        opacity: 0;
      }
    }
  }
}

@media (max-width: 750px) {
  .music-player {
    left: 0;
    transform: translateX(0);
  }
}

.joy .back {
  box-shadow: 0 0 2px #ccc;
}

$sunSize: 3em;
.loading-things {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0, 0, 0);
  color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease;
  &.hide {
    visibility: hidden;
  }
  .sun-wrap {
    position: relative;
    padding: 0 1em;
  }
  .sun {
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      bottom: -5px;
      height: 0;
      border: 4px solid var(--backgroundColor);
      box-shadow: 0px 10px 20px var(--backgroundColor);
      border-radius: 4px;
    }
    height: $sunSize;
    width: $sunSize * 2;
    border-top-left-radius: $sunSize * 2;
    border-top-right-radius: $sunSize * 2;
    box-shadow: 0px -1px 10px rgb(204, 181, 51);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(2, 0, 36, 1) 2%,
      rgba(224, 165, 6, 1) 3%,
      rgba(224, 165, 6, 1) 10%,
      rgba(2, 0, 36, 1) 10%,
      rgba(2, 0, 36, 1) 12%,
      rgba(255, 232, 99) 13%,
      rgba(255, 232, 99) 20%,
      rgba(2, 0, 36, 1) 20%,
      rgba(2, 0, 36, 1) 22%,
      rgba(224, 165, 6, 1) 23%,
      rgba(224, 165, 6, 1) 30%,
      rgba(2, 0, 36, 1) 30%,
      rgba(2, 0, 36, 1) 32%,
      rgba(255, 232, 99) 33%,
      rgba(255, 232, 99) 40%,
      rgba(2, 0, 36, 1) 40%,
      rgba(2, 0, 36, 1) 42%,
      rgb(224, 165, 6, 1) 43%,
      rgb(224, 165, 6, 1) 50%,
      rgba(2, 0, 36, 1) 50%,
      rgba(2, 0, 36, 1) 52%,
      rgba(255, 232, 99) 53%,
      rgba(255, 232, 99) 60%,
      rgba(2, 0, 36, 1) 60%,
      rgba(2, 0, 36, 1) 62%,
      rgba(224, 165, 6, 1) 63%,
      rgba(224, 165, 6, 1) 70%,
      rgba(2, 0, 36, 1) 70%,
      rgba(2, 0, 36, 1) 72%,
      rgba(255, 232, 99) 73%,
      rgba(255, 232, 99) 80%,
      rgba(2, 0, 36, 1) 80%,
      rgba(2, 0, 36, 1) 82%,
      rgba(224, 165, 6, 1) 83%,
      rgba(224, 165, 6, 1) 90%,
      rgba(2, 0, 36, 1) 90%,
      rgba(2, 0, 36, 1) 92%,
      rgba(255, 232, 99) 93%,
      rgba(255, 232, 99) 100%
    );
    background-size: 200% 200%;

    -webkit-animation: sunAnimation 20s ease infinite;
    -moz-animation: sunAnimation 20s ease infinite;
    animation: sunAnimation 20s linear infinite;
  }

  @-webkit-keyframes sunAnimation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 200% 200%;
    }
  }
  @-moz-keyframes sunAnimation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 200% 200%;
    }
  }
  @keyframes sunAnimation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 200% 200%;
    }
  }
  .start-wrap {
    margin-top: 1.5em;
    height: 35px;
  }
  .start-button {
    display: none;
    font-family: 'tickingTimeBomb', sans-serif;
    margin-bottom: 0;
    width: 100px;
    height: 35px;
    font-size: 16pt;
    background: black;
    border: solid 2px white;
    color: white;
    overflow: hidden;
    text-align: center;
    animation: openStartAnimation 2s ease-in;
    cursor: pointer;
    transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;
    box-shadow: 0px 0px 2px white;
    &:hover {
      background-color: var(--backgroundColor);
      box-shadow: 0px 0px 10px var(--backgroundColor);
    }
    &.show {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @keyframes openStartAnimation {
    0% {
      width: 0px;
      height: 0px;
      border: solid 0px white;
      color: black;
    }
    25% {
      width: 0px;
      height: 35px;
      border: solid 1px white;
      color: black;
    }
    35% {
      border: solid 2px white;
    }
    50% {
      color: white;
    }
    100% {
      color: white;
      width: 100px;
    }
  }
}
